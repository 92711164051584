import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import Navbar from '../layout/Navbar/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { allCourse, enrollCourse, myCourseAction, SSOLogin } from '../../actions/courseAction';
// import { Link } from "react-router-dom";
import liveSkillData from '../Data/liveSkillData'
import courseData from '../Data/courseData'
import Loader from '../layout/Loader/Loader'
import Activity from './Cards/Activity';
import RecCard from './Cards/RecCard';
import RecData from '../Data/RecData';
import MyCourseCard from './Cards/MyCourseCard';
import UpcomingCard from './Cards/UpcomingCard';
import LiveSessionData from '../Data/liveSessionData';

import { LiaTelegramPlane } from "react-icons/lia";
// import { PiListStarLight } from "react-icons/pi";
// import { PiListChecks } from "react-icons/pi";
import { PiCheckSquareOffsetLight } from "react-icons/pi";
import { FaUserShield } from "react-icons/fa6";

const Dashboard = () => {
  const dispatch = useDispatch()

  const { isAuthenticated, user } = useSelector((state) => state.user)
  const { loading, all_course } = useSelector((state) => state.allCourse)
  const { sso } = useSelector((state) => state.SSO)
  const { loading: courseLoading, report_data, enroll_course, my_course } = useSelector((state) => state.myCourse)
  
  const [confirmModal, setConfirmModal] = useState(false)
  const [enrollCourseData, setEnrollCourseData] = useState({
    name: user.name,
    email: user.email,
    batch_id: ''
  })

  const userEmail = user.email
  const data = RecData.filter((elm) => (elm.branch === (user.branch.id || user.branch._id)) && (Number(elm.sem) === user.semester))
  const external_batch = all_course?.filter((item) => data?.some((recc) => item.external_batch_id === recc.Batch_id))
  const recCourse = external_batch?.filter((item) => !my_course?.some((course) => item.external_batch_id === course.external_batch_id))
  // console.log(external_batch[0].external_batch_id) 
  const handleEnrollConfirmation = () => {
    dispatch(enrollCourse(enrollCourseData))
    setConfirmModal(false)
  }
  
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(myCourseAction(userEmail))
      dispatch(SSOLogin(userEmail))
    }
    // if (external_batch && external_batch.length> 0 ) {
    //   console.log("called")
    //   dispatch(report({ batch_id: external_batch[0].external_batch_id })) 
    // }

    if (enroll_course) {
      window.location.href = `${sso}`
    }
    dispatch(allCourse())
  }, [dispatch, isAuthenticated, userEmail, enroll_course, sso, external_batch])
  return (
    <>
      {loading && recCourse && courseLoading ? <Loader /> : (
        <>
          <div className='dashboard_conatainer'>
            <Navbar />
            <div className='confirmModal_container' style={confirmModal ? { display: 'flex' } : { display: 'none' }}>
              <h1 className='confirmModal_heading'>Do you want to enroll in this course</h1>
              <div className='confirmModal_button_parent'>
                <button className='confirmModal_button' onClick={handleEnrollConfirmation}>Yes</button>
                <button className='confirmModal_button' onClick={() => setConfirmModal(false)}>No</button>
              </div>
            </div>

            <div className='dash_body'>
              <div className='das_body_left'>
                {report_data && (
                  <div className='leaderboard_container'>
                    <div className='leaderboard_left'>
                      <h1 className='leaderboard_heading'>{report_data?.courseName}</h1>
                      <div className='leaderboard_card'>
                        <h1 className='leaderboard_progress_text'>{Math.floor(report_data?.progressPercentage)}%</h1>
                        {/* <Link to='/report' className='taskBtn'>View all tasks</Link> */}
                      </div>
                      <progress id="task" max="100" value={report_data?.progressPercentage} className='leaderboard_progress'>{report_data?.courseName}</progress>
                    </div>
                    <div className='leaderboard_line'></div>
                    <div className='leaderboard_right'>
                      <div className='data_point_container'>
                        <div className='data_point_parent'>
                          <h1 className='data_point_heading'><span style={{ fontSize: '1.5rem' }}>{report_data?.completedTask}</span> of {report_data?.totalTask}</h1>
                          <div className='data_point_icon_parent'>
                            <LiaTelegramPlane />
                            <span style={{ marginLeft: '0.5rem' }}>Tasks Submitted</span>
                          </div>
                        </div>

                        <div className='data_point_parent'>
                          <h1 className='data_point_heading'><span style={{ fontSize: '1.5rem' }}>{report_data?.completedTopics}</span> of {report_data?.totalTopics}</h1>
                          <div className='data_point_icon_parent'>
                            <PiCheckSquareOffsetLight />
                            <span style={{ marginLeft: '0.5rem' }}>Topics Completed</span>
                          </div>
                        </div>

                        <div className='data_point_parent'>
                          <h1 className='data_point_heading'><span style={{ fontSize: '1.5rem' }}>{report_data?.studentPosition}</span> of {report_data?.totalStudent}</h1>
                          <div className='data_point_icon_parent'>
                            <FaUserShield />
                            <span style={{ marginLeft: '0.5rem' }}>Your Position</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )}

                {my_course?.length > 0 && <h1 className='main_heading'>My Courses</h1>}
                <div className='dash_my_course_container'>
                  {my_course?.map((elm, index) => {
                    return <MyCourseCard data={elm} key={index} setConfirmModal={setConfirmModal} enrollCourseData={enrollCourseData} setEnrollCourseData={setEnrollCourseData} />
                  })}
                </div>

                {recCourse?.length > 0 && <h1 className='main_heading'>Recommended couses for you</h1>}
                <div className='dash_rec_container'>
                  {recCourse && recCourse?.map((elm, index) => {
                    return <RecCard data={elm} key={index} setConfirmModal={setConfirmModal} enrollCourseData={enrollCourseData} setEnrollCourseData={setEnrollCourseData} />
                  })}
                </div>

                <h1 className='main_heading'>Upcoming courses for you</h1>
                <div className='dash_up_container'>
                  {courseData?.map((elm, index) => (
                    <UpcomingCard data={elm} key={index} />
                  ))}
                </div>
              </div>
              <div className='das_body_right'>
                <h1 className='main_heading'>Life skills</h1>
                <div className="activity_container">
                  {liveSkillData?.map((elm, index) => (
                    <Activity data={elm} key={index} />
                  ))}
                </div>

                <h1 className='main_heading'>Live session</h1>
                <div className='liveSession_container'>
                  {LiveSessionData?.map((elm, index) => (
                    <UpcomingCard data={elm} key={index} />
                  ))}
                </div>
              </div>
            </div>

          </div>
        </>
      )}
    </>
  )
}

export default Dashboard

// const ConfirmModal = ({confirmModal, setConfirmModal, handleEnrollConfirmation}) => {
//   return (
//     <>
//       <div className='confirmModal_container' style={confirmModal ? {display: 'flex'}: {display: 'none'}}>
//         <h1 className='confirmModal_heading'>Do you want to enroll in this course</h1>
//         <div className='confirmModal_button_parent'>
//           <button className='confirmModal_button' onClick={handleEnrollConfirmation}>Yes</button>
//           <button className='confirmModal_button' onClick={() => setConfirmModal(false)}>No</button>
//         </div>
//       </div>
//     </>
//   )
// }