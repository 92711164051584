export const GET_UNIVERSITY_REQUEST = "GET_UNIVERSITY_REQUEST";
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS";
export const GET_UNIVERSITY_FAIL = "GET_UNIVERSITY_FAIL";

export const GET_COLLEGE_REQUEST = "GET_COLLEGE_REQUEST";
export const GET_COLLEGE_SUCCESS = "GET_COLLEGE_SUCCESS";
export const GET_COLLEGE_FAIL = "GET_COLLEGE_FAIL";

export const GET_BRANCH_REQUEST = "GET_BRANCH_REQUEST";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAIL = "GET_BRANCH_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";