    const StudentData = [
     {
      "name": "Srinidhi Salunke",
      "email": "srinidhisalunke09@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "21\/10\/2024",
      "startTime": "3:18:59 PM",
    //   "completedDate": "21\/10\/2024",
      "completeTime": "3:21:26 PM",
      "USN": "2JR19EC021"
     },
     {
      "name": "Meghana TM",
      "email": "meghanatm66@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:35:21 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:27 PM",
      "USN": "4UB23EC402"
     },
     {
      "name": "Srushti  KS",
      "email": "srushtiks0910@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:35:36 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:37:57 PM",
      "USN": "4UB23EC405"
     },
     {
      "name": "Kavya s",
      "email": "ks7941146@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:35:58 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:37:49 PM",
      "USN": "4UB21IP007"
     },
     {
      "name": "Sneha Gouda ",
      "email": "snehapgouda2004@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:02 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:45:12 PM",
      "USN": "4UB21CS051"
     },
     {
      "name": "SHESHADRI R",
      "email": "sheshadrirampur@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:03 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:37:11 PM",
      "USN": "4UB21EE042"
     },
     {
      "name": "Sri Navitha",
      "email": "shreenavitha0@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:09 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:37:51 PM",
      "USN": "4UB21IP014"
     },
     {
      "name": "Sneha R",
      "email": "354sneha@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:11 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:06 PM",
      "USN": "4UB22EE408"
     },
     {
      "name": "Anuradha Giriyannanavar",
      "email": "anuradha.pg2003@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:19 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:12 PM",
      "USN": "4UB21EE062"
     },
     {
      "name": "Jyothika M",
      "email": "jyothikam1103@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:19 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:46:34 PM",
      "USN": "4UB21IP006 "
     },
     {
      "name": "Manoj N",
      "email": "manojdatamail04@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:25 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:37:38 PM",
      "USN": "4UB21ME031"
     },
     {
      "name": "Sinchana Doddagoudra",
      "email": "sinchanadoddagoudra@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:31 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:43:22 PM",
      "USN": "4UB22EC059"
     },
     {
      "name": "Deepika  M S ",
      "email": "deepikamgs2309@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:32 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:39 PM",
      "USN": "4UB21EE016"
     },
     {
      "name": "Veena  H G ",
      "email": "gamanagattiv@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:35 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:40:53 PM",
      "USN": "4UB22EC069"
     },
     {
      "name": "Tejaswini  H S",
      "email": "tejaswinihs613@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:41 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:12 PM",
      "USN": "4UB21IP017"
     },
     {
      "name": "samarth lakkar",
      "email": "samarthlakkar@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:41 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:43 PM",
      "USN": "4UB21MEO46"
     },
     {
      "name": "Vinutha  B R",
      "email": "vinuthabr7676@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:46 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:45 PM",
      "USN": "4UB21CS057"
     },
     {
      "name": "Yashodha  D H",
      "email": "yashodhadh544@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:47 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:38:47 PM",
      "USN": "4UB21EE061"
     },
     {
      "name": "Sonu R",
      "email": "ssonu322170@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:36:47 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:34 PM",
      "USN": "4UB23EC404"
     },
     {
      "name": "Sharanabasava Kanakagiri",
      "email": "sharanukanakagiri9@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:17 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:40:41 PM",
      "USN": "4UB22EC052"
     },
     {
      "name": "Sahana K S",
      "email": "sahanaks1308@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:20 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:48 PM",
      "USN": "4UB21CS044"
     },
     {
      "name": "shankar b",
      "email": "sb6287657@gmail.com",
      "semester": 6,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:32 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:21 PM",
      "USN": "4UB22EC051"
     },
     {
      "name": "Shreeharsha A S",
      "email": "sh268966@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:39 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:34 PM",
      "USN": "4UB22EC056"
     },
     {
      "name": "Ashwini  P R ",
      "email": "ashwinipr467@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:41 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:41:02 PM",
      "USN": "4UB21CS005"
     },
     {
      "name": "Nataraja B",
      "email": "bnatarajbnataraj777@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:37:59 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:39:38 PM",
      "USN": "4UB21CS034"
     },
     {
      "name": "meghana patil ",
      "email": "apmeghana3@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:38:34 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:40:03 PM",
      "USN": "4UB21EE063"
     },
     {
      "name": "ADARSHA  R",
      "email": "adarshaadi6363@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:38:38 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:46:46 PM",
      "USN": "4UB22ME401"
     },
     {
      "name": "Ganesh  Huyilagol ",
      "email": "drganeshmhuyilagol@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:11 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:42:46 PM",
      "USN": "4UB21ME017"
     },
     {
      "name": "PRAJWAL S",
      "email": "prajwalprajwal2720@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:14 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:43:25 PM",
      "USN": "4UB22ME414"
     },
     {
      "name": "CHANDANA S M chandana S M",
      "email": "chandana13102003@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:31 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:44:07 PM",
      "USN": "4UB21EE012"
     },
     {
      "name": "Chandana T M",
      "email": "nagamanicm1972@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:34 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:41:47 PM",
      "USN": "4UB22EC015"
     },
     {
      "name": "Bindu  T S",
      "email": "tsbindu80@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:39 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:46:06 PM",
      "USN": "4UB21EE008"
     },
     {
      "name": "Naga laxmi",
      "email": "naga81878@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:39:45 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:41:52 PM",
      "USN": "4UB21EE029"
     },
     {
      "name": "Rehana  Sultana",
      "email": "rehanasyeds786@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:40:05 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:44:28 PM",
      "USN": "4UB21EE037 "
     },
     {
      "name": "KASHINATH  JAMADAR ",
      "email": "jamadarkashinath103@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:40:22 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:42:58 PM",
      "USN": "4UB22ME408"
     },
     {
      "name": "Bharath  T H",
      "email": "bharathvishwa091@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:40:48 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:42:24 PM",
      "USN": "4UB21ME010 "
     },
     {
      "name": "Anusha  Gurunath Bannihatti ",
      "email": "anushagb12@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:41:23 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:44:30 PM",
      "USN": "4UB21EE002"
     },
     {
      "name": "kavya BD",
      "email": "kavyadevendrappa207@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:43:54 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:46:34 PM",
      "USN": "4UB21EE024"
     },
     {
      "name": "Akanksha A Kakade",
      "email": "akankshaakakade@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:44:40 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:47:06 PM",
      "USN": "4UB22EC005"
     },
     {
      "name": "jeevan P B",
      "email": "jeevanpbjeeva1234@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:44:43 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:48:34 PM",
      "USN": "4UB22ME407"
     },
     {
      "name": "Murugesh  S B",
      "email": "murugeshsb555@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:45:10 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:47:37 PM",
      "USN": "4UB21ME034"
     },
     {
      "name": "shivakumar Korannanavar ",
      "email": "shivakumar8971367405@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:45:35 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:48:19 PM",
      "USN": "4UB22ME41I"
     },
     {
      "name": "PRAJWAL  MYAKAL ",
      "email": "myakalprajwal08@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:46:21 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:48:34 PM",
      "USN": "4UB21ME039"
     },
     {
      "name": "BHARATH KUMAR  M",
      "email": "bharathkumar99457@gmail.com",
      "semester": 7,
      "branch": "EE",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:47:19 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:52:49 PM",
      "USN": "4UB21EE006"
     },
     {
      "name": "Anusha C",
      "email": "anu.sha30110@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:50:07 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:51:45 PM",
      "USN": "4U22CS003"
     },
     {
      "name": "Deepa B M",
      "email": "deepamaner1234@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:50:31 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:53:03 PM",
      "USN": "4UB22CS013"
     },
     {
      "name": "Sindhu B",
      "email": "sindhubanakar279@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "12:51:55 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "12:53:12 PM",
      "USN": "4UB22CS045"
     },
     {
      "name": "Sathish  S",
      "email": "sathishs12052003@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "University B. D. T College of Engineering, Davangere",
    //   "startDate": "25\/10\/2024",
      "startTime": "2:44:16 PM",
    //   "completedDate": "25\/10\/2024",
      "completeTime": "2:47:37 PM",
      "USN": "4UB21ME047"
     },
     {
      "name": "Vishnu M",
      "email": "vishnupablo43@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:20:57 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:23:34 PM",
      "USN": 49
     },
     {
      "name": "vinay  M",
      "email": "vinayitiger31@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:21:26 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:24:26 PM",
      "USN": "4GM22AI119"
     },
     {
      "name": "Swati  Gantotti ",
      "email": "swatigantotti2005@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:21:47 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:24:44 PM",
      "USN": 32
     },
     {
      "name": "Praneet  Hegde ",
      "email": "praneethegdekatta@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:14 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:24:27 PM",
      "USN": "4GM22AI069"
     },
     {
      "name": "Shweta  Basanagouda Doddagoudar ",
      "email": "shwetadoddagoudru@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:25 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:28:26 PM",
      "USN": "4GM22AI100"
     },
     {
      "name": "Rashi  EM",
      "email": "emrashi44@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:44 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:26:16 PM",
      "USN": 12
     },
     {
      "name": "Sujay  V",
      "email": "sujjuv2003@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:45 PM",
    //   "completedDate": "27\/10\/2024",
      "completeTime": "12:38:09 PM",
      "USN": "4GM22AI109"
     },
     {
      "name": "Shrusti  Gonal",
      "email": "shrustigonal@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:48 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:28:50 PM",
      "USN": 23
     },
     {
      "name": "Junaid Farhan",
      "email": "farhanjunaid568@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:51 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:25:58 PM",
      "USN": "4GM22AI040"
     },
     {
      "name": "Prajwal  Shegunashi ",
      "email": "prajwalshegunashi60@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:22:58 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:24:25 PM",
      "USN": 3
     },
     {
      "name": "shalini km",
      "email": "shalinikmshalini51@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:23:21 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:25:30 PM",
      "USN": "Gmit2024aiml"
     },
     {
      "name": "Mitali  Rangani ",
      "email": "pmitali2005@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:23:29 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:25:54 PM",
      "USN": "4GM22AI048"
     },
     {
      "name": "shreya CN",
      "email": "shreyacn2@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:23:30 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:27:22 PM",
      "USN": "4GM22AI098"
     },
     {
      "name": "Yashaswini B G  Yashaswini ",
      "email": "yashubg28@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:23:55 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:26:43 PM",
      "USN": 41
     },
     {
      "name": "Vanshika B R",
      "email": "vanshikabottolanda@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:01 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:26:37 PM",
      "USN": "4GM22AI117"
     },
     {
      "name": "hamika narla",
      "email": "hamikanarla@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:23 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:27:09 PM",
      "USN": "4GM22AI053"
     },
     {
      "name": "Shireesha  Y A",
      "email": "shireesha152004@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:24 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:31:22 PM",
      "USN": "4GM22AI091"
     },
     {
      "name": "vibha v vernekar",
      "email": "vibhavvernekar6@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:29 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:26:35 PM",
      "USN": 35
     },
     {
      "name": "Bhoomika C.A",
      "email": "bhoomikaca2004@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:57 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:28:13 PM",
      "USN": "4GM22AI018 "
     },
     {
      "name": "Suma  h s",
      "email": "sumahsamshasi@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:24:57 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:26:30 PM",
      "USN": "4GM22AI110"
     },
     {
      "name": "yashas yashas ",
      "email": "yashasswamy564564@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:25:16 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:29:05 PM",
      "USN": "4GM22AI125"
     },
     {
      "name": "Pragnya  Anand Obalappanavar",
      "email": "pragnyaobalappanavar@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:25:41 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:28:32 PM",
      "USN": "4GM22AI065"
     },
     {
      "name": "Megha  R N",
      "email": "rnmegha38@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:26:35 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:30:51 PM",
      "USN": "4GM22AI046 "
     },
     {
      "name": "Bhavana T B",
      "email": "bhavanatb8@gmail.com",
      "semester": 7,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:26:42 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:30:31 PM",
      "USN": "4GM22AI400"
     },
     {
      "name": "CR  Vedhavyas ",
      "email": "veduc95@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:27:09 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:31:12 PM",
      "USN": "4GM23AI401"
     },
     {
      "name": "Yashoda maharajapete",
      "email": "ymaharajpete@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:28:06 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:29:57 PM",
      "USN": "4GM23AI411"
     },
     {
      "name": "Punith Kumar AB",
      "email": "punithkumar0927@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:32:37 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:36:09 PM",
      "USN": "4GM22AI073"
     },
     {
      "name": "swathi b r",
      "email": "swathibr143@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:38:16 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:41:19 PM",
      "USN": 31
     },
     {
      "name": "Vidya Patil",
      "email": "vidyaranirp10@gmail.com",
      "semester": 7,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "12:41:44 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "12:52:12 PM",
      "USN": "4GM21AI058"
     },
     {
      "name": "Megha  Bhat",
      "email": "bhatmegha62003@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "4:21:24 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "4:22:35 PM",
      "USN": "4GM22AI045"
     },
     {
      "name": "Nisarga  K R",
      "email": "nisargakratakalle@gmail.com",
      "semester": 5,
      "branch": "AI and ML",
      "college": "GM Institute of Technology",
    //   "startDate": "26\/10\/2024",
      "startTime": "10:48:18 PM",
    //   "completedDate": "26\/10\/2024",
      "completeTime": "10:51:32 PM",
      "USN": "4GM22AI057"
     },
     {
      "name": "Sujal Hotkar",
      "email": "kingsujal.op@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:57:32 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:43 PM",
      "USN": "D42"
     },
     {
      "name": "Sachetan Kurangi",
      "email": "sachetankurangi@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:04 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:09 PM",
      "USN": "D30"
     },
     {
      "name": "vikas  ghooli",
      "email": "vikasghooli726@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:12 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:42 PM",
      "USN": "D45"
     },
     {
      "name": "Bhimashi  Kidadal ",
      "email": "kidadalbhimashi@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:24 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:02:04 PM",
      "USN": "A (18)"
     },
     {
      "name": "Hemant  Patil ",
      "email": "hp450970@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:25 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "2:59:41 PM",
      "USN": "A28"
     },
     {
      "name": "anish  hulbutte ",
      "email": "hulabutteanish@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:29 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:01 PM",
      "USN": 4
     },
     {
      "name": "Deepak Mane",
      "email": "manedeepak679@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:29 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:17 PM",
      "USN": "A24"
     },
     {
      "name": "Apeksha  Rawool",
      "email": "apeksharawool19@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:34 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:12 PM",
      "USN": "A14"
     },
     {
      "name": "Akash Hudali",
      "email": "akashhudali9844@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:37 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:12 PM",
      "USN": "D05"
     },
     {
      "name": "CHANDRU  HIREMATH ",
      "email": "chandruhiremath756@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:43 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:12 PM",
      "USN": "A19"
     },
     {
      "name": "sadiya mulla",
      "email": "sadiyamulla312@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:43 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:52 PM",
      "USN": "D31"
     },
     {
      "name": "pradnya  shetty",
      "email": "pradnyashetti44@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:44 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:08 PM",
      "USN": "D22"
     },
     {
      "name": "Kartik Nandeshwar",
      "email": "kartiknandeshwar17@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:44 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:54 PM",
      "USN": "C16"
     },
     {
      "name": "Harish sankavvagol ",
      "email": "sankavvagolharish@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:45 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:03 PM",
      "USN": "D15"
     },
     {
      "name": "Rahul kokare ",
      "email": "kokarep3402@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:45 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:10 PM",
      "USN": "C33"
     },
     {
      "name": "muhammadateeque  kazi",
      "email": "atiqkazi140@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:46 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:36 PM",
      "USN": 24
     },
     {
      "name": "afsa kudachi ",
      "email": "afsakudachi65@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:48 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:03 PM",
      "USN": 5
     },
     {
      "name": "Anup Mutnal",
      "email": "mutnalanup@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:48 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:10 PM",
      "USN": "A11"
     },
     {
      "name": "Aryan Sakari",
      "email": "aryansakari99@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:51 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:28 PM",
      "USN": "C06"
     },
     {
      "name": "Adarsh  Gurav ",
      "email": "adarshgurav9290@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:52 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:42 PM",
      "USN": "D02"
     },
     {
      "name": "Madiha  Abdul razak ",
      "email": "madihaabdulrazak6@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:53 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:09 PM",
      "USN": 47
     },
     {
      "name": "Tanuja Gadennavar ",
      "email": "tanujagadennavar@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:58:53 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:04:37 PM",
      "USN": 67
     },
     {
      "name": "Kushal Shetty",
      "email": "kus.shetty23@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:03 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:02 PM",
      "USN": "C20"
     },
     {
      "name": "Lalabi  Hulikatti",
      "email": "maibubhulikatti9@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:03 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:16:42 PM",
      "USN": "A35"
     },
     {
      "name": "SHANKAR MULIMANI",
      "email": "shankarmulimani2005@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:04 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:44 PM",
      "USN": "C65"
     },
     {
      "name": "Sanika  Amrolkar ",
      "email": "sanikaamrolkar@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:04 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:00:45 PM",
      "USN": "C37"
     },
     {
      "name": "divya b",
      "email": "divya.bhairanatti@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:04 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:05 PM",
      "USN": "001"
     },
     {
      "name": "shreenivas  karoshi ",
      "email": "shreenivaskaroshi18@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:05 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:15:37 PM",
      "USN": 42
     },
     {
      "name": "vidya  huggi",
      "email": "vidyarhuggi20@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:14 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:14:25 PM",
      "USN": 56
     },
     {
      "name": "Faieeza  jamadar ",
      "email": "jamdarjulekha99@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:21 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:04:48 PM",
      "USN": "A26"
     },
     {
      "name": "Chetan Hallad",
      "email": "chetanhallad7@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:35 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:02:27 PM",
      "USN": "A(20)"
     },
     {
      "name": "Akshay Nerli",
      "email": "akshaynerli@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:35 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:18 PM",
      "USN": "D07"
     },
     {
      "name": "BHOJRAJ  Tonennavar",
      "email": "prashanttonennavar@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:39 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:08:33 PM",
      "USN": "D12"
     },
     {
      "name": "Sumit Jakkannavar ",
      "email": "sumitjakkannavar888@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:39 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:06:49 PM",
      "USN": "C52"
     },
     {
      "name": "Sneha Umesh  Bagalkoti ",
      "email": "snehabagalkotibagalkoti@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:45 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:07:21 PM",
      "USN": "D38"
     },
     {
      "name": "Abhaycharan  supe",
      "email": "abhaycharansupebit@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:47 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:29 PM",
      "USN": "A02"
     },
     {
      "name": "Seema  Khanapuri ",
      "email": "seemakhanapuri9@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:47 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:58 PM",
      "USN": 38
     },
     {
      "name": "Amruta  Hiremath ",
      "email": "amrutamhiremath123@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "2:59:52 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:02:24 PM",
      "USN": 3
     },
     {
      "name": "NAVEEN KUMAR",
      "email": "ramazunt437@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:00:01 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:10:50 PM",
      "USN": 27
     },
     {
      "name": "Shriraksha  Kamannavar",
      "email": "sakamannavar@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:00:01 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:07:14 PM",
      "USN": 36
     },
     {
      "name": "bhumika  patil",
      "email": "bhumikapatil9972@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:00:04 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:02:25 PM",
      "USN": 13
     },
     {
      "name": "kartik Fularkoppa",
      "email": "kartiknf9@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:00:05 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:01:13 PM",
      "USN": "A31"
     },
     {
      "name": "Pranav Ganachari",
      "email": "pranavganachari6@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:00:12 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:03:02 PM",
      "USN": "D 23"
     },
     {
      "name": "Anjali  Patil",
      "email": "anjalirpatil2124@gmail.com",
      "semester": 1,
      "branch": "CS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:01:10 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:06:41 PM",
      "USN": "A08"
     },
     {
      "name": "Radhika P Gaddi",
      "email": "radhikapgaddi@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:02:06 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:10:21 PM",
      "USN": "C32"
     },
     {
      "name": "Shreyash Astagi",
      "email": "shreyashastagi081@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:02:12 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:06:03 PM",
      "USN": "C45"
     },
     {
      "name": "Nandan  Madawal",
      "email": "nandanmadawal06@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:04:09 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:05:54 PM",
      "USN": "C61"
     },
     {
      "name": "Soumya Satti",
      "email": "soumyasatti243@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:04:10 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:07:25 PM",
      "USN": "C49"
     },
     {
      "name": "naveen  kundaragi",
      "email": "abdnavee17@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:04:12 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:05:58 PM",
      "USN": "C26"
     },
     {
      "name": "Amarsingh  vadrali ",
      "email": "amarsinghvadrali2@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:04:19 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:07:45 PM",
      "USN": "A7"
     },
     {
      "name": "Damodar  Kulkarni ",
      "email": "damodarkulkarni.26122006@gmail.com",
      "semester": 1,
      "branch": "IS",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:06:13 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:08:07 PM",
      "USN": "A21"
     },
     {
      "name": "Vaishnavi Krishna Mirajkar",
      "email": "vaishnavikmirajkar2006@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:07:02 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:14:10 PM",
      "USN": 55
     },
     {
      "name": "Mahananda reddy",
      "email": "mahanandareddy21@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:07:27 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:09:37 PM",
      "USN": "C21"
     },
     {
      "name": "Deepa patil ",
      "email": "deepapatil4046@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:07:42 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:17:42 PM",
      "USN": "C10"
     },
     {
      "name": "Jyoti d kalasannavar kalasannavar",
      "email": "jyotikalasannavar6@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:09:49 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:16:13 PM",
      "USN": 15
     },
     {
      "name": "Shripal Akkiwate",
      "email": "shripalakkiwate@gmail.com",
      "semester": 1,
      "branch": "AI and ML",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:11:26 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:13:52 PM",
      "USN": "D54"
     },
     {
      "name": "Shankaragoud patil",
      "email": "ismartshankar0384@gmail.com",
      "semester": 1,
      "branch": "EC",
      "college": "Bharatesh Institute of Technology, Belagavi",
    //   "startDate": "28\/10\/2024",
      "startTime": "3:11:31 PM",
    //   "completedDate": "28\/10\/2024",
      "completeTime": "3:13:28 PM",
      "USN": "C41"
     },
     {
      "name": "Abhishek Huded",
      "email": "abhishekhuded28@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:19 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:10 AM",
      "USN": "2JR21EC001"
     },
     {
      "name": "Laxmi Bharamshetti",
      "email": "laxmishetti12@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:33 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:19 AM",
      "USN": "2JR21CS038"
     },
     {
      "name": "Rohit  gorbal",
      "email": "rohitgorbal5911@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:34 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:46 AM",
      "USN": "2JR21EC032"
     },
     {
      "name": "Shridhar Kamble",
      "email": "shridharkamble0622@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:34 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:32:48 AM",
      "USN": "2JR22ME419 "
     },
     {
      "name": "prathviraj nayak",
      "email": "prathvirajnk620@gmail.com",
      "semester": 7,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:37 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:36:29 AM",
      "USN": "2JR21CV005"
     },
     {
      "name": "prasanna  budavi ",
      "email": "budaviprasanna@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:38 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:32:59 AM",
      "USN": "2jr21cs057"
     },
     {
      "name": "Vikramaditya  Reshmi ",
      "email": "vikramadityareshmi@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:42 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:48 AM",
      "USN": "2JR21CS115"
     },
     {
      "name": "Vaishnavi Vernekar ",
      "email": "vaishnavivernekar96@gmail.com",
      "semester": 4,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:46 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:35:25 AM",
      "USN": "2JR21CS111"
     },
     {
      "name": "Anil Bandivaddar",
      "email": "anilmbandivaddar1999@gmail.com",
      "semester": 7,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:48 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:36:28 AM",
      "USN": "2JR21CV001"
     },
     {
      "name": "Sourabh  karadi",
      "email": "sourabhkaradi432@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:49 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:28 AM",
      "USN": "2JR21EC046"
     },
     {
      "name": "Vijaylaxmi  Rahut",
      "email": "vijaylaxmirahut@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:49 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:38:55 AM",
      "USN": "2JR21CS114"
     },
     {
      "name": "Shweta  Nayak",
      "email": "snshweta6364@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:51 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:34:30 AM",
      "USN": "2JR21CS095"
     },
     {
      "name": "Tanushree  Divate ",
      "email": "divatetanu65@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:54 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:36:54 AM",
      "USN": "2JR21CS109 "
     },
     {
      "name": "Chaitra  Jorapur ",
      "email": "chaitrajorapur@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:55 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:38:20 AM",
      "USN": "2JR21EC007"
     },
     {
      "name": "Mudassir Sanderwale",
      "email": "kmudassirt@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:55 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:06 AM",
      "USN": "2JR21CS044"
     },
     {
      "name": "Glory  Kodamalla ",
      "email": "glorykodamalla@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:31:57 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:19 AM",
      "USN": "2JR21CS026 "
     },
     {
      "name": "S S",
      "email": "warmachine1817@gmail.com",
      "semester": 4,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:00 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:22 AM",
      "USN": "2JR21CS103"
     },
     {
      "name": "sanjay Pujeri ",
      "email": "sanjaypujeri254@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:06 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:34:04 AM",
      "USN": "2JR21CS083"
     },
     {
      "name": "Sohail Nadaf",
      "email": "sohailnadaf66@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:09 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:34:57 AM",
      "USN": "2JR22CS416"
     },
     {
      "name": "Sumit H",
      "email": "sumithalluri560@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:09 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:39:12 AM",
      "USN": "2JR21EC048"
     },
     {
      "name": "Akanksha Bedkihale",
      "email": "akkibedkihale1008@gmail.com",
      "semester": 7,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:10 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:51 AM",
      "USN": "2JR21CS008"
     },
     {
      "name": "Aditya Narasai",
      "email": "narasaiaditya@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:11 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:42:24 AM",
      "USN": "2JR21EC002"
     },
     {
      "name": "Rahul Teradal ",
      "email": "rahulteradal45@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:15 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:29 AM",
      "USN": "2JR21EC030"
     },
     {
      "name": "Swapnil  Jadhav",
      "email": "swapj74@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:16 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:35:35 AM",
      "USN": "2JR21CS108"
     },
     {
      "name": "Sanika Chopade",
      "email": "saneeka007@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:24 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:37:06 AM",
      "USN": "2JR21EC037"
     },
     {
      "name": "Jotiba dure",
      "email": "jotibadure23@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:35 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:35:04 AM",
      "USN": "2JR22CS403"
     },
     {
      "name": "Sakshi  Chougule",
      "email": "sakshijc15.1008@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:43 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:37:09 AM",
      "USN": "2JR21EC033"
     },
     {
      "name": "Sudha Gangadharmath ",
      "email": "sudhapg2002@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:44 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:38:27 AM",
      "USN": "2Jr21cs104"
     },
     {
      "name": "Sourabh M",
      "email": "mathapati011@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:45 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:33:47 AM",
      "USN": "2JR21EC045"
     },
     {
      "name": "Shrihari  Katti",
      "email": "kattishrihari45@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:45 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:34:30 AM",
      "USN": "2JR21CS090"
     },
     {
      "name": "Samarth Suresh Lankeppanavar ",
      "email": "samarthlankeppanvar@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:32:53 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:34:57 AM",
      "USN": "2JR21EC034"
     },
     {
      "name": "Abhishek  Chougule ",
      "email": "abhishekchougule222@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:05 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:46:36 AM",
      "USN": "2JR22EC401"
     },
     {
      "name": "MAHAVEER JAKKANNAVAR",
      "email": "mahaveerjakkannavar10@gmail.com",
      "semester": 7,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:05 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:46:47 AM",
      "USN": "2JR22CV401"
     },
     {
      "name": "Samarth  kamat",
      "email": "kamatsamarth04@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:07 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:38:17 AM",
      "USN": "2JR21CS079"
     },
     {
      "name": "Sapana Satish Chanabasanavar Chanabasanavar",
      "email": "chanabasanavarmaruti@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:12 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:44:13 AM",
      "USN": "2jr21ec038"
     },
     {
      "name": "Faizaan Ahamad Soudagar",
      "email": "soudagarfaizan2@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:14 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:36:48 AM",
      "USN": "2JR21EC010"
     },
     {
      "name": "Samiksha  Marve",
      "email": "samikshamarve566@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:33:14 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:54:01 AM",
      "USN": "2JR21EC035"
     },
     {
      "name": "PRASANNA  GULER",
      "email": "prasannaguler5@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:34:01 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:36:54 AM",
      "USN": "2jr22me414"
     },
     {
      "name": "Bhushan A  Kakati",
      "email": "bhushankakati47@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:36:13 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:45:37 AM",
      "USN": "2JR22EC404"
     },
     {
      "name": "Bhakti  Dhanna ",
      "email": "bhaktidhanna07@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:36:17 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:39:48 AM",
      "USN": "2JR21CS017 "
     },
     {
      "name": "Om Mandalik",
      "email": "om.shivaji.mandalik@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:36:19 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:41:09 AM",
      "USN": "2JE21CS050"
     },
     {
      "name": "Aktharhusen  Matte",
      "email": "matteaktharhusen@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:36:22 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:38:42 AM",
      "USN": "2JR21ME005"
     },
     {
      "name": "Dhanyashree M Raykar ",
      "email": "manjuvr0606@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:36:29 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:37:49 AM",
      "USN": "2JR21CS021"
     },
     {
      "name": "Adarsh  patil ",
      "email": "patiladarsh182@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:37:01 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:39:31 AM",
      "USN": "2jr22me400"
     },
     {
      "name": "Manthan Patil",
      "email": "patilmanthan493@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:37:05 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:39:57 AM",
      "USN": "2jr22me409"
     },
     {
      "name": "pushpa Hiravannavar ",
      "email": "hiravannavarpushpa@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:38:12 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:40:22 AM",
      "USN": "2jr22cs408"
     },
     {
      "name": "Vinita  Harihar",
      "email": "vinitarh15@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:38:25 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:47:39 AM",
      "USN": "2JR21EC055"
     },
     {
      "name": "Mahesh Ghasti",
      "email": "maheshghasti344@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:39:12 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:41:29 AM",
      "USN": "2JR22ME407"
     },
     {
      "name": "kartik  karimudakannavr ",
      "email": "kartiksk222@gmail.com",
      "semester": 7,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:40:15 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:43:58 AM",
      "USN": "2JR20CV412"
     },
     {
      "name": "Abhishek  Kanbarkar ",
      "email": "abhishekkanbarkar81@gmail.com",
      "semester": 7,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:41:05 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:43:26 AM",
      "USN": "2JR21ME003"
     },
     {
      "name": "Snehal Patole",
      "email": "snehalpatole6030@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:45:15 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:46:42 AM",
      "USN": "2JR21CS098"
     },
     {
      "name": "rushikesh  patil ",
      "email": "rp04rushikesh@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:51:22 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:54:12 AM",
      "USN": "2JR21CS075"
     },
     {
      "name": "Akhilesh  Shetti ",
      "email": "akhileshshetti@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:52:29 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:53:48 AM",
      "USN": "2JR21EC003"
     },
     {
      "name": "Praveena Patil",
      "email": "praveen72836728@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:54:07 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:55:45 AM",
      "USN": "2JR21CS058"
     },
     {
      "name": "Mandar Goavakar",
      "email": "mandargoavakar@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:06 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:32:22 AM",
      "USN": "2JR22EC024"
     },
     {
      "name": "Saurabh Kumar singh",
      "email": "siktara9999@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:35 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:39:06 AM",
      "USN": "2JR22CS088"
     },
     {
      "name": "VARUN KURHADE",
      "email": "varunkurhade32@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:48 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:29 AM",
      "USN": "2JR22CS041"
     },
     {
      "name": "Shrusti  Allagi ",
      "email": "shrustiallagi@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:48 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:36:45 AM",
      "USN": "2JR22CS096"
     },
     {
      "name": "Shreeraj Mattigatti",
      "email": "shreerajbm2482@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:55 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:19 AM",
      "USN": "2JR22CS092"
     },
     {
      "name": "Vaishnavi  Morti ",
      "email": "vaishu0622morti@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:56 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:20 AM",
      "USN": "2JR22CS047"
     },
     {
      "name": "Sakshi Hatti",
      "email": "hattisakshi@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:31:59 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:37 AM",
      "USN": "2JR22CS077 "
     },
     {
      "name": "Joseph Guduri",
      "email": "josephgcollege@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:03 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:08 AM",
      "USN": "2JR22ME005"
     },
     {
      "name": "JYOTI TUPPAD",
      "email": "jyotirtuppad@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:04 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:37:32 AM",
      "USN": "2JR22CS038"
     },
     {
      "name": "manoj  b",
      "email": "jcerquizz@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:13 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:36:48 AM",
      "USN": "2JR22EC0264"
     },
     {
      "name": "Sonu Yadav",
      "email": "sonuskyadav20@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:19 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:38 AM",
      "USN": "2JR22CS103"
     },
     {
      "name": "Aishwarya Oulkar",
      "email": "aishwaryaoulkar@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:21 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:02 AM",
      "USN": "2JR22EC002"
     },
     {
      "name": "samruddhi  Arabhavi ",
      "email": "samruddhiarabhavi@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:22 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:48 AM",
      "USN": "2JR22CS080"
     },
     {
      "name": "snehal p",
      "email": "snehalpunekar2@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:30 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:15 AM",
      "USN": "2jr23ec406"
     },
     {
      "name": "Yusuf  chonche",
      "email": "yusufchonche0@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:32 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:13 AM",
      "USN": "2JR22ME031"
     },
     {
      "name": "Ishwari  Hiremath ",
      "email": "ishwarihiremath591@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:33 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:47 AM",
      "USN": "2JR22EC017 "
     },
     {
      "name": "Shubham  Akanoji ",
      "email": "shubhamakanoji@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:34 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:38:26 AM",
      "USN": "2JR23CS409"
     },
     {
      "name": "nidhi  mane",
      "email": "nidhimane2604@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:38 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:33:45 AM",
      "USN": "2JR22EC029"
     },
     {
      "name": "Sourabh Khot",
      "email": "krsourabh009@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:40 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:36:09 AM",
      "USN": "2JR22CS107"
     },
     {
      "name": "Pooja  Benade ",
      "email": "poojabenade03@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:45 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:00 AM",
      "USN": "2JR22EC032"
     },
     {
      "name": "Ketan Jadhav",
      "email": "ketanjadhav959@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:32:55 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:36:32 AM",
      "USN": "2JR22ME008"
     },
     {
      "name": "Driti  Birje ",
      "email": "dritibirje03@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:33:04 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:35:57 AM",
      "USN": "2JR22CS033"
     },
     {
      "name": "Shrusti  Patil ",
      "email": "shrusti1008@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:33:07 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:38:35 AM",
      "USN": "2JR22CS097"
     },
     {
      "name": "Pooja Suresh karguppikar  karguppikar ",
      "email": "poojakarguppikar71@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:33:07 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:57 AM",
      "USN": "2JR22ME015"
     },
     {
      "name": "Sanjana Melavanki",
      "email": "melavankisanjana4@gmail.com",
      "semester": 5,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:33:17 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:50 AM",
      "USN": "2JR22EC049"
     },
     {
      "name": "Vinuta Patil",
      "email": "patilvinuta7@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:33:28 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:34:48 AM",
      "USN": "2JR22ME029"
     },
     {
      "name": "Tejashwini  Matolli ",
      "email": "tejuum95@gmail.com",
      "semester": 5,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:35:15 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:38:59 AM",
      "USN": "2JR22CS117"
     },
     {
      "name": "Ranjita  Vaddebail ",
      "email": "ranjitavaddebail001@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:35:16 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:39:10 AM",
      "USN": "2JR22CS067"
     },
     {
      "name": "Srushti Paramaj ",
      "email": "srushtiparamaj@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:35:17 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:39:20 AM",
      "USN": "2JR22CS109"
     },
     {
      "name": "sandhya devalatkar",
      "email": "sandhyadevalatkar40@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:37:01 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:42:22 AM",
      "USN": "2JR22ME024"
     },
     {
      "name": "Spoorti K",
      "email": "spoortikilliketar@gmail.com",
      "semester": 5,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:37:11 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:39:00 AM",
      "USN": "2JR23CV404 "
     },
     {
      "name": "monali  kedar",
      "email": "monalikedar3198@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:37:29 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:39:51 AM",
      "USN": "2JR22CS046 "
     },
     {
      "name": "Altaf shekali ",
      "email": "altafshekali16@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:38:06 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:41:52 AM",
      "USN": "2JR22CS013"
     },
     {
      "name": "Vaishnavi  Yalgulkar ",
      "email": "vaishnaviyalgukar871@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:38:07 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:46:40 AM",
      "USN": "2JR22CS121"
     },
     {
      "name": "Pradnya  Sutar ",
      "email": "22sonupradnya@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:41:39 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:43:01 AM",
      "USN": "2JR22CS057 "
     },
     {
      "name": "Priya Shivapur ",
      "email": "priyashivapur01@gmail.com",
      "semester": 5,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:43:55 AM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:45:59 AM",
      "USN": "2JR23CS404"
     },
     {
      "name": "Ananya  Patil",
      "email": "ananyabpatil1008@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:07:53 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:15 PM",
      "USN": "2JR23CI003"
     },
     {
      "name": "shrishail  atar",
      "email": "jcer1037@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:07:55 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:09:40 PM",
      "USN": "2JR23EC071"
     },
     {
      "name": "Alok Kadadaral",
      "email": "try.alok09@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:07:58 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:44 PM",
      "USN": "2JR23CS012 "
     },
     {
      "name": "Aditya  Patil ",
      "email": "adityavpatil818@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:04 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:39 PM",
      "USN": "2JR2ECI001"
     },
     {
      "name": "Lakshmi  Tarase ",
      "email": "laxmitarase12@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:09 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:20:05 PM",
      "USN": "2JR23CS040"
     },
     {
      "name": "Rutuja  Pakhare ",
      "email": "rutujapakhare23@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:11 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:02 PM",
      "USN": "2JR23CI035 "
     },
     {
      "name": "Sampurna  Karadiguddi ",
      "email": "sampurnajeevan26@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:20 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:44 PM",
      "USN": "2JR23CS083"
     },
     {
      "name": "Sujeet  Pawar",
      "email": "pawarshrimanta@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:21 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:51 PM",
      "USN": "2JR23CI052"
     },
     {
      "name": "Vaishnavi  kole",
      "email": "vaishnavikole052@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:21 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:01 PM",
      "USN": "2JR23CS120"
     },
     {
      "name": "sanjana  zangaruche ",
      "email": "sanjana.zangaruche05@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:22 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:12 PM",
      "USN": "2JR23CI040"
     },
     {
      "name": "Annapurna  Ganachari ",
      "email": "ganachariannapurna29@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:23 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:09:52 PM",
      "USN": "2JR23EC008"
     },
     {
      "name": "VINAYASHREE KAMLAKAR",
      "email": "vinayashree2005@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:25 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:18 PM",
      "USN": "2JR23CS124"
     },
     {
      "name": "Kavana Ambekar",
      "email": "kavvv.anna317@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:37 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:37 PM",
      "USN": "2JR23CI014"
     },
     {
      "name": "suraj  mullur",
      "email": "rajumullur105@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:40 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:14:32 PM",
      "USN": "2JR23EC081"
     },
     {
      "name": "prarthana Shivanand  koujalagi ",
      "email": "prarthanakoujalagi80@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:48 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:33 PM",
      "USN": "2JR23CS064"
     },
     {
      "name": "omkar Magadum ",
      "email": "omkarmagadum2004@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:50 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:17 PM",
      "USN": "2JR23EC038"
     },
     {
      "name": "Anushree Ajatdesai",
      "email": "anuajatdesai@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:08:55 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:14:05 PM",
      "USN": "2JR23CI006"
     },
     {
      "name": "poorvita  Hiremath ",
      "email": "poorvitavh@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:03 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:58 PM",
      "USN": "2JR23CI026"
     },
     {
      "name": "sakshi bhosale",
      "email": "bhosalesakshi749@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:09 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:05 PM",
      "USN": "2JR23CI037"
     },
     {
      "name": "Srushti Gunjikar",
      "email": "gunjikarsrushti@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:10 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:40 PM",
      "USN": "2JR23CI049"
     },
     {
      "name": "Preeti Patil",
      "email": "pp187155@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:22 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:15:14 PM",
      "USN": "2JR23CS068"
     },
     {
      "name": "Mohini  Gurav ",
      "email": "mohinigurav512@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:23 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:34 PM",
      "USN": "2JR23CS048"
     },
     {
      "name": "Rohan sk",
      "email": "rohan2005kamble@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:29 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:45 PM",
      "USN": "2JR23EC054"
     },
     {
      "name": "Madhu Maganur",
      "email": "madhumaganur02@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:29 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:38 PM",
      "USN": "2JR23EC027"
     },
     {
      "name": "Prathmesh Redekar",
      "email": "prathmeshredekar16@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:30 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:05 PM",
      "USN": "2JR23CI032"
     },
     {
      "name": "vaishnavi  rathod ",
      "email": "vaishnavir464@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:35 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:15:08 PM",
      "USN": "2jr23ci057"
     },
     {
      "name": "Prathamesh Devalapurkar",
      "email": "devalapratham2005@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:38 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:15:56 PM",
      "USN": "2JR23CI031"
     },
     {
      "name": "Srushti  Ammanage ",
      "email": "srushtiammanage@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:38 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:12:37 PM",
      "USN": "2JR23CI048"
     },
     {
      "name": "suresh hundre",
      "email": "sureshhundre8970@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:09:43 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:10:57 PM",
      "USN": "2JR23CS110"
     },
     {
      "name": "vaishnavi  patil",
      "email": "2005vaishnavipatil@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:10:03 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:13:23 PM",
      "USN": "2JR23CI056 "
     },
     {
      "name": "Ajit Goture ",
      "email": "ajitgoture5156@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:10:20 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:11:48 PM",
      "USN": "2JR23CS007 "
     },
     {
      "name": "Laxmi Kalyani",
      "email": "laxmikalyani010@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:26:02 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:34:04 PM",
      "USN": "2JR23CS041 "
     },
     {
      "name": "SAHIL  KURBET ",
      "email": "avsahilkurbet.10b@gmail.com",
      "semester": 3,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:29:30 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:30:59 PM",
      "USN": "2JR23EC058"
     },
     {
      "name": "Shashidhar Gandroli",
      "email": "shashigandroli@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:52:52 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:54:07 PM",
      "USN": "2JR23ME041"
     },
     {
      "name": "ANAND  JANGALE ",
      "email": "anandjanagale18@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:03 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:07 PM",
      "USN": 13
     },
     {
      "name": "shravani  patil",
      "email": "shravanipatil934@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:05 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:17 PM",
      "USN": 14
     },
     {
      "name": "sujal  hiremath",
      "email": "sujalhiremath9440@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:18 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:54:35 PM",
      "USN": "2JR23ME047"
     },
     {
      "name": "Prem Shahapurkar",
      "email": "shahapurkarprem68@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:24 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:07 PM",
      "USN": 12
     },
     {
      "name": "Manojkumar H",
      "email": "manojkumarbh082@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:27 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:54:47 PM",
      "USN": "2JR23ME021"
     },
     {
      "name": "Sumeet Gadakari ",
      "email": "sumeetg408@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:30 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:54:58 PM",
      "USN": "2JR23ME048"
     },
     {
      "name": "Hrishikesh Rajput",
      "email": "hrishikeshrajput2004@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:33 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:56:00 PM",
      "USN": "2JR23ME015"
     },
     {
      "name": "Shahid Pendari",
      "email": "shahidpendari@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:55 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:55:09 PM",
      "USN": "2JR23ME040"
     },
     {
      "name": "Rohit Mangani",
      "email": "rohitmangani@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:53:55 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:17 PM",
      "USN": 9844169308
     },
     {
      "name": "sairaj birje",
      "email": "birje0201@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:08 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:56:21 PM",
      "USN": "2JR23ME034 "
     },
     {
      "name": "Aditya  badiger",
      "email": "badigeraditya109@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:09 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:58:59 PM",
      "USN": "2jr23me001"
     },
     {
      "name": "Pratibha Rathod",
      "email": "rathodpratibha883@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:10 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:57 PM",
      "USN": 20
     },
     {
      "name": "raghavendra  shet",
      "email": "rshet89@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:15 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:58:21 PM",
      "USN": "2JR23ME029"
     },
     {
      "name": "prashant Sonappanavar",
      "email": "prashantsonappanavar271@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:21 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:58:40 PM",
      "USN": "2JR23CV006"
     },
     {
      "name": "Avanish Yadav",
      "email": "yavanish877@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:24 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:57:46 PM",
      "USN": "2JR23ME007 "
     },
     {
      "name": "omkar  pujari ",
      "email": "pujariomkar13@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:54:34 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:59:54 PM",
      "USN": 8867487235
     },
     {
      "name": "Akash H",
      "email": "hakash7499@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:55:29 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "1:07:17 PM",
      "USN": "2JR23ME002"
     },
     {
      "name": "sachin lakamoji",
      "email": "sachinlakamoji76@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:55:42 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "1:00:42 PM",
      "USN": "2jr23me033"
     },
     {
      "name": "Irfan Matte",
      "email": "irfanmatte16@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:55:42 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:59:04 PM",
      "USN": "2JR23ME017"
     },
     {
      "name": "Akash Benni",
      "email": "akashbenni09@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:55:59 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:59:29 PM",
      "USN": "New admission"
     },
     {
      "name": "ansh shet",
      "email": "anshshet222@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:56:54 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "12:59:07 PM",
      "USN": "2JR23ME004"
     },
     {
      "name": "Chetan  Mirji",
      "email": "chetanmirji1604@gmail.com",
      "semester": 3,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:57:45 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "1:02:29 PM",
      "USN": "2JR23CV005"
     },
     {
      "name": "santosh awade",
      "email": "santoshawade1@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "12:57:52 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "1:00:47 PM",
      "USN": "2JR20ME001"
     },
     {
      "name": "Rohit Ambewadkar",
      "email": "rohitambewadkar1991@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "1:01:46 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "1:05:10 PM",
      "USN": "2JR23ME031"
     },
     {
      "name": "Vitthal  Gavade ",
      "email": "gavadevitthal831@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "5:51:12 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "5:53:01 PM",
      "USN": "2JR23ME054"
     },
     {
      "name": "Nandan  Indulkar ",
      "email": "nandanindulkar13@gmail.com",
      "semester": 5,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "5:57:02 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "6:00:55 PM",
      "USN": "2jr23me408"
     },
     {
      "name": "Pradnya Patil",
      "email": "pradnyapatil0805@gmail.com",
      "semester": 3,
      "branch": "AI and ML",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "6:16:39 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "6:21:07 PM",
      "USN": "2JR23CI027"
     },
     {
      "name": "Akshata Mane",
      "email": "akshatamane8418@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "7:12:49 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "7:14:14 PM",
      "USN": "2JR21CS010"
     },
     {
      "name": "Shruti  Nilajkar ",
      "email": "shrutinilajkar63@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "8:35:38 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "8:37:16 PM",
      "USN": "2JR21CS091"
     },
     {
      "name": "Pallavi Bendigeri",
      "email": "pallavibendigeri3@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "9:05:34 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "9:06:22 PM",
      "USN": "2JR21EC024"
     },
     {
      "name": "Tejaswini Patil",
      "email": "tejaswinipatil99670@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "9:25:57 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "9:27:57 PM",
      "USN": "2JR22EC411"
     },
     {
      "name": "Vaishnavi  Pattar",
      "email": "vaishnavipattar14@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "9:57:52 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:02:30 PM",
      "USN": "2JR23CS116"
     },
     {
      "name": "Swapnil  Chinchane ",
      "email": "swapnilchinchane736@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "10:24:57 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "10:27:37 PM",
      "USN": "2JR23CS112 "
     },
     {
      "name": "Neeraj Nair",
      "email": "neeru.nair04@gmail.com",
      "semester": 3,
      "branch": "ME",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "29\/10\/2024",
      "startTime": "11:18:47 PM",
    //   "completedDate": "29\/10\/2024",
      "completeTime": "11:21:22 PM",
      "USN": "2JR23ME025"
     },
     {
      "name": "Pruthviraj  Patil",
      "email": "pruthvirajpatil9375@gmail.com",
      "semester": 3,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "01\/11\/2024",
      "startTime": "8:14:40 PM",
    //   "completedDate": "01\/11\/2024",
      "completeTime": "8:18:07 PM",
      "USN": "303CS20028 "
     },
     {
      "name": "Deepa  Muragod ",
      "email": "deepamuragod19@gmail.com",
      "semester": 5,
      "branch": "CE",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "04\/11\/2024",
      "startTime": "5:44:43 PM",
    //   "completedDate": "04\/11\/2024",
      "completeTime": "5:47:13 PM",
      "USN": "2JR22CV003"
     },
     {
      "name": "Rajashree  Bhekane ",
      "email": "rajashreebhekane@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "05\/11\/2024",
      "startTime": "10:57:57 AM",
    //   "completedDate": "05\/11\/2024",
      "completeTime": "11:01:58 AM",
      "USN": "2jr21cs067"
     },
     {
      "name": "Anjali Ambewadi",
      "email": "anjaliambewadi@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "08\/11\/2024",
      "startTime": "11:24:52 AM",
    //   "completedDate": "08\/11\/2024",
      "completeTime": "11:27:32 AM",
      "USN": "2JR22EC402"
     },
     {
      "name": "Aqsa shaikh",
      "email": "aqsashaikh22a@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "08\/11\/2024",
      "startTime": "11:26:04 AM",
    //   "completedDate": "08\/11\/2024",
      "completeTime": "11:27:51 AM",
      "USN": "2JR22EC403"
     },
     {
      "name": "Ayesha  khanum ",
      "email": "ayeshakhan24059@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:04:54 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:57 AM",
      "USN": "1HM21CS011"
     },
     {
      "name": "Varsha K.S",
      "email": "varsha.k.s.5533@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:04:54 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "6:28:51 PM",
      "USN": "1HM21CS108"
     },
     {
      "name": "KARAN SAIBANE",
      "email": "saibanekaran@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:07 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:06:32 AM",
      "USN": "1HM21CS029"
     },
     {
      "name": "Jamsina Jafar",
      "email": "jamsinajafar@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:08 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:01 AM",
      "USN": "1HM21CS026"
     },
     {
      "name": "Priyanka  N",
      "email": "priyankapriyanka1287@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:10 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:08:09 AM",
      "USN": "1HM21CS073"
     },
     {
      "name": "Avinash Dhoble",
      "email": "dhobleavinash71@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:14 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:13 AM",
      "USN": "1HM21CS010 "
     },
     {
      "name": "MUHAMMED MUQHTADEER",
      "email": "muhammedmuzawar9@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:14 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:15:21 AM",
      "USN": "1HM21CS061"
     },
     {
      "name": "Ambika Girani",
      "email": "ambikajb424@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:17 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:49 AM",
      "USN": "1HM21CS008"
     },
     {
      "name": "Farhan Khan",
      "email": "farhanindia44@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:17 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:40 AM",
      "USN": "1HM21CS017"
     },
     {
      "name": "Goutham  U S",
      "email": "gouthamu7888@gmail.com",
      "semester": 4,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:18 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:23 AM",
      "USN": "1HM21CS022"
     },
     {
      "name": "Rakesh  H N ",
      "email": "rakeshz1947@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:23 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:12:06 AM",
      "USN": "1hm22cs405"
     },
     {
      "name": "AFTAB ULLA AF",
      "email": "aftab.kunigal@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:23 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:34 AM",
      "USN": "1HM21CS004"
     },
     {
      "name": "Varsha Jambur",
      "email": "jamburvarsha@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:27 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:56 AM",
      "USN": "1HM21CS109"
     },
     {
      "name": "mohammed mudassir m",
      "email": "mdmudassir8882@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:28 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:43 AM",
      "USN": "1HM21CS051 "
     },
     {
      "name": "Riyaz .",
      "email": "rr8680578@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:32 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:11 AM",
      "USN": "1HM21CS076"
     },
     {
      "name": "A Shwetha ",
      "email": "sshwetha1763@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:34 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:08:18 AM",
      "USN": "1HM21CS001"
     },
     {
      "name": "mamata  Mulakeri ",
      "email": "mamatamulakeri@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:35 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:08:09 AM",
      "USN": "1HM21CS038 "
     },
     {
      "name": "chandana bv chandana bv",
      "email": "chandanavaradegowda18@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:35 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:16:19 AM",
      "USN": "1hm21ec004"
     },
     {
      "name": "Mohamed  Mubarak ",
      "email": "mohamedmubarak09181@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:36 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:26 AM",
      "USN": "1HM21CS043"
     },
     {
      "name": "Harshitha  Rao",
      "email": "harshitharao426@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:37 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:19:44 AM",
      "USN": "1HM21CS024"
     },
     {
      "name": "Leelaprasad  B R",
      "email": "leelaprasadbr0@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:41 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:09:10 AM",
      "USN": "1HM21CS033"
     },
     {
      "name": "Manasa  N S",
      "email": "manasamanasa75131@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:43 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:09:22 AM",
      "USN": "1hm21ec009"
     },
     {
      "name": "Mahesh  Umachagi ",
      "email": "buntyboss0225@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:43 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:09:31 AM",
      "USN": "1HM21CS037"
     },
     {
      "name": "Brunda  M",
      "email": "b4662271@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:05:49 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:35 AM",
      "USN": "1HM21CS014"
     },
     {
      "name": "Waseem  Akram Khan",
      "email": "waseemakrampathan07@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:05 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:27 AM",
      "USN": "1HM21CS110"
     },
     {
      "name": "harsha hegde k",
      "email": "harshaarya8861@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:07 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:34:49 AM",
      "USN": "1HM21CS023"
     },
     {
      "name": "Mohammed Uvaise",
      "email": "mohammeduvaise44@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:09 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:49 AM",
      "USN": "1HM21CS057"
     },
     {
      "name": "Revathi PS Revathi",
      "email": "rrevathi673@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:10 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:09:14 AM",
      "USN": "1HM21CS074"
     },
     {
      "name": "MD AZFAR TAQUEE",
      "email": "azfartaquee007@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:11 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:07:53 AM",
      "USN": "1HM21CS041"
     },
     {
      "name": "Gourav basutkar",
      "email": "gouravbasutkar8@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:19 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:08:12 AM",
      "USN": "1HM21CS021"
     },
     {
      "name": "mohammed  umar ",
      "email": "mdumer2803@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:44 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:08:02 AM",
      "USN": "1HM21CS056"
     },
     {
      "name": "Faiza Fathima",
      "email": "faizafathima46@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:06:44 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:10:14 AM",
      "USN": "1HM21CS027"
     },
     {
      "name": "Abhinay   kumar ",
      "email": "pattarabhi121@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:08:03 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:20:48 AM",
      "USN": "1HM21EC002"
     },
     {
      "name": "Heena Kousar",
      "email": "hk3146375@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:09:08 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:13:03 AM",
      "USN": "1HM21EC007 "
     },
     {
      "name": "Latha HC",
      "email": "lathahclathahc46@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:13:23 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:25:29 AM",
      "USN": "1HM22EC402"
     },
     {
      "name": "Ranganatha  Gowda HR",
      "email": "gowdaranganatha8@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:14:20 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:18:36 AM",
      "USN": "1HM22EC404"
     },
     {
      "name": "Nandan  k",
      "email": "nandank1187@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:14:33 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:18:38 AM",
      "USN": "1HM22EC403"
     },
     {
      "name": "Vishwanath Betageri",
      "email": "vbetageri66@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:14:51 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:17:34 AM",
      "USN": "1HM21EC015"
     },
     {
      "name": "nalina  nalina m",
      "email": "nalinamgvt@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:15:58 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:17:36 AM",
      "USN": "1HM21CS063 "
     },
     {
      "name": "Prakruthi L E Prakruthi L E",
      "email": "prakruthile@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:17:27 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:21:35 AM",
      "USN": "1HM21CS070"
     },
     {
      "name": "Lalu Prasad S",
      "email": "lalu09585@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "12\/11\/2024",
      "startTime": "11:20:47 AM",
    //   "completedDate": "12\/11\/2024",
      "completeTime": "11:22:26 AM",
      "USN": "1HM21EC008"
     },
     {
      "name": "karthik  KV",
      "email": "karthikkv0066@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "HMS INSTITUTE OF TECHNOLOGY",
    //   "startDate": "13\/11\/2024",
      "startTime": "9:15:04 AM",
    //   "completedDate": "13\/11\/2024",
      "completeTime": "9:16:26 AM",
      "USN": "1HM22CS403"
     },
     {
      "name": "Pratiksha  Patil",
      "email": "pratikshapatil.003@gmail.com",
      "semester": 7,
      "branch": "EC",
      "college": "JAIN COLLEGE OF ENGINEERING & RESEARCH",
    //   "startDate": "21\/11\/2024",
      "startTime": "8:45:00 PM",
    //   "completedDate": "21\/11\/2024",
      "completeTime": "8:46:29 PM",
      "USN": "2JR21ECO28 "
     },
     {
      "name": "Harshit Tiwary",
      "email": "harshit.king2003@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:32:51 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:34:41 AM",
      "USN": "1NH21CE022"
     },
     {
      "name": "UDAY  KARTHIK S",
      "email": "da1762745@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:33:19 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:34:43 AM",
      "USN": "1NH21CS249"
     },
     {
      "name": "Dasari Teja",
      "email": "tejadasari144@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:33:30 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:34:44 AM",
      "USN": "1NH21CS064"
     },
     {
      "name": "Thejeswar  Reddy",
      "email": "jogathejesh@gmail.com",
      "semester": 7,
      "branch": "CS",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:33:33 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:34:49 AM",
      "USN": "1NH21CS113"
     },
     {
      "name": "Vempalli Charitha",
      "email": "vempallicharitha04@gmail.com",
      "semester": 7,
      "branch": "AI and ML",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:34:09 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:35:53 AM",
      "USN": "1NH21AI125"
     },
     {
      "name": "Rajasree RS",
      "email": "rajasreecse@gmail.com",
      "semester": 7,
      "branch": "AI and ML",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:35:16 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:37:14 AM",
      "USN": "SI123"
     },
     {
      "name": "Varun M S",
      "email": "varunkumarswamy097@gmail.com",
      "semester": 7,
      "branch": "AI and ML",
      "college": "New Horizon College of Engineering",
    //   "startDate": "23\/11\/2024",
      "startTime": "11:35:43 AM",
    //   "completedDate": "23\/11\/2024",
      "completeTime": "11:36:42 AM",
      "USN": "1NH22AI411 "
     }
    ]

    export default StudentData;