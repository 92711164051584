import { ALL_COURSES_FAIL, ALL_COURSES_REQUEST, ALL_COURSES_SUCCESS, MY_COURSES_FAIL, MY_COURSES_REQUEST, MY_COURSES_SUCCESS, CLEAR_ERRORS, ENROLL_COURSE_REQUEST, ENROLL_COURSE_SUCCESS, ENROLL_COURSE_FAIL, SSO_LOGIN_REQUEST, SSO_LOGIN_SUCCESS, SSO_LOGIN_FAIL, USER_REPORT_REQUEST, USER_REPORT_SUCCESS, USER_REPORT_FAIL } from '../constants/courseConstant'
import axios from "axios"

// Action for get my course
export const myCourseAction = (email) => async (dispatch) => {
    try {
        dispatch({ type: MY_COURSES_REQUEST });

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.get(`/api/v1/student_InterlibCourses/${email}`, config);

        dispatch({ type: MY_COURSES_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: MY_COURSES_FAIL, payload: error.response.data.message })
    }
}

// Action for get all course
export const allCourse = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_COURSES_REQUEST })

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.get('/api/v1/all_InterlibCourses', config);

        dispatch({ type: ALL_COURSES_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: ALL_COURSES_FAIL, payload: error.response.data.message })
    }
}

// Action for enroll course
export const enrollCourse = (enrollCourse) => async (dispatch) => {

    try {
        dispatch({ type: ENROLL_COURSE_REQUEST })

        const config = { headers: { "Content-Type": "application/json" } }

        const { data } = await axios.post('/api/v1/enrollStudentOn_Interlib', enrollCourse, config);
        console.log(data)
        dispatch({ type: ENROLL_COURSE_SUCCESS, payload: data.success })
    } catch (error) {
        dispatch({ type: ENROLL_COURSE_FAIL, payload: error.response.data.message })
    }
}

// Action for Report
export const report = (batch_id) => async (dispatch) => {
    try {
        dispatch({ type: USER_REPORT_REQUEST })
        
        const config = { headers: { "Content-Type": "application/json" } }

        const { data } = await axios.post('/api/v1/leaderBoard', batch_id, config);
        
        dispatch({ type: USER_REPORT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: USER_REPORT_FAIL, payload: error.response.data.message })
    }
}

// Action for SSO
export const SSOLogin = (email) => async (dispatch) => {
    try {
        dispatch({ type: SSO_LOGIN_REQUEST })

        const config = { headers: { "Content-Type": "application/json" } }

        const { data } = await axios.get(`/api/v1/interlibLogin/${email}`, config);

        dispatch({ type: SSO_LOGIN_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: SSO_LOGIN_FAIL, payload: error.response.data.message })
    }
}

// clearing errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS })
}