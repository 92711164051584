import { configureStore } from '@reduxjs/toolkit'
import { forgotPasswordReducer, profileReducer, userReducer, verificationReducer } from './reducers/userReducer'
import { allCourseReducer, myCourseReducer, ssoLoginReducer } from './reducers/courseReducer';
import { academicDataReducer } from './reducers/academicDataReducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        forgotPassword: forgotPasswordReducer,
        profile: profileReducer,
        myCourse: myCourseReducer,
        allCourse: allCourseReducer,
        academicData: academicDataReducer,
        verification: verificationReducer,
        SSO: ssoLoginReducer
    }
})
export default store;