import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.user)

  return (
    isAuthenticated ? <Outlet /> : <Navigate to='/login' />
  )
}

export default ProtectedRoute;
