import React from 'react'
import "./App.css"
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { loadUser } from './actions/userAction.js'
import ProtectedRoute from './component/Route/ProtectedRoute.js'
import WebFont from 'webfontloader'
// import Profile from "./component/User/Profile.js"
import store from "./store.js"
import Register from './component/User/Register.js'
import Login from './component/User/Login.js'
import EmailVerification from './component/User/EmailVerification.js'
import Onboarding from './component/User/Onboarding.js'
import Dashboard from './component/Home/Dashboard.js'
import LandingPage from './component/Home/LandingPage.js'
// import Report from './component/Home/Report.js'
import { loadUser } from './actions/userAction.js'
import ForgotPassword from './component/User/ForgotPassword.js'
import ResetPassword from './component/User/ResetPassword.js'
import SendMail from './adminComponent/SendMail.js'
import Footer from './component/layout/Footer/Footer.js'
// import AllCourses from './component/Home/AllCourses.js'

const App = () => {

  const { isAuthenticated } = useSelector((state) => state.user)
  console.log(isAuthenticated)
  // Primary font - montserrat, secondary font - Roboto flex
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto Flex", "Montserrat"]
      }
    })

    store.dispatch(loadUser())
  }, [])

  return (
    <>
      {/* <Header /> */}
      {/* {isAuthenticated && <UserOptions user={user} />} */}
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/sendMail' element={<SendMail />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/verify' element={<EmailVerification />} />
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/password/forgot' element={<ForgotPassword />} />
        <Route path='/password/reset/:token' element={<ResetPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/dashboard' element={<Dashboard />} />
          {/* <Route path='/report' element={<Report />} /> */}
          {/* <Route path='/profile' element={<Profile />} /> */}
          {/* <Route path='/all-courses' element={<AllCourses />} /> */}
        </Route>
      </Routes>
      <Footer />
    </>
  )
}

export default App
