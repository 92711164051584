import Tea from './image/teaWithTirumal.jpg'
import Curiosity from './image/curiosityWithKushal.jpg'

const LiveSessionData = [
    {
        course_name: "Tea with Tirumal",
        url: Tea,
    },
    {
        course_name: "Curiosity with Kushal",
        url: Curiosity,
    },
]

export default LiveSessionData
