const RecData = [
    // Sem - 1
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "1",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "1",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "1",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "1",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "1",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 2
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "2",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "2",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "2",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "2",
        "Batch_id": "GAI240101"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "2",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 3
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "3",
        "Batch_id": "GAICE240201"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "3",
        "Batch_id": "GAIME240201"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "3",
        "Batch_id": "GAIEC240201"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "3",
        "Batch_id": "GAICS240201"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "3",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 4
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "4",
        "Batch_id": "GAICE240201"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "4",
        "Batch_id": "GAIME240201"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "4",
        "Batch_id": "GAIEC240201"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "4",
        "Batch_id": "GAICS240201"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "4",
        "Batch_id": "GAIBME2401"
    },

    // sem - 5
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "5",
        "Batch_id": "GAICE240301"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "5",
        "Batch_id": "GAIME240301"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "5",
        "Batch_id": "GAIEC240301"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "5",
        "Batch_id": "GAICS240301"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "5",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 6
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "6",
        "Batch_id": "GAICE240301"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "6",
        "Batch_id": "GAIME240301"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "6",
        "Batch_id": "GAIEC240301"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "6",
        "Batch_id": "GAICS240301"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "6",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 7
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "7",
        "Batch_id": "GAICE240401"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "7",
        "Batch_id": "GAIME240401"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "7",
        "Batch_id": "GAIEC240401"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "7",
        "Batch_id": "GAICS240401"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "7",
        "Batch_id": "GAIBME2401"
    },

    // Sem - 8
    {
        "branch": "6477df575e21f533c359a02c",
        "sem": "8",
        "Batch_id": "GAICE240401"
    },
    {
        "branch": "6477df435e21f533c359a026",
        "sem": "8",
        "Batch_id": "GAIME240401"
    },
    {
        "branch": "648959553cdb3172f4d429d7",
        "sem": "8",
        "Batch_id": "GAIEC240401"
    },
    {
        "branch": "6477dedc5e21f533c359a023",
        "sem": "8",
        "Batch_id": "GAICS240401"
    },
    {
        "branch": "675d6b491f03522c1d47f189",
        "sem": "8",
        "Batch_id": "GAIBME2401"
    },
]

export default RecData