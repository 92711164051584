import React from 'react'
import './MyCourseCard.css'
import { useSelector } from 'react-redux'
import Gai from '../images/GAI.jpg'

const MyCourseCard = ({ data }) => {
  const { sso } = useSelector((state) => state.SSO)
  const handleEnroll = () => {
    window.location.href = `${sso}`
  }
  return (
    <>
      <div
        // to={course_page_url}
        // style={{ textDecoration: "none" }}
        className='course_card courseCard_container'
      >
        <div
          style={{
            backgroundImage: `url(${Gai})`,
          }}
          className='course_card_img'
        ></div>
        <h1 className="course_name">{data?.course_name}</h1>
        {data.external_batch_id ? <button className='enroll_button' onClick={handleEnroll}>Resume now</button> : null}
      </div>
    </>
  )
}

export default MyCourseCard
