import innovate from './image/innovate.jpg'
import campus from './image/campus.jpg'
import reasoning from './image/Reasoning.jpg'

const liveSkillData = [
    {
        "name": "Explore to innovate",
        "image": innovate
    },
    {
        "name": "Clean Campus Crusade",
        "image": campus
    },
    {
        "name": "Reson With Reasoning",
        "image": reasoning
    }
]

export default liveSkillData;