export const MY_COURSES_REQUEST = "MY_COURSES_REQUEST";
export const MY_COURSES_SUCCESS = "MY_COURSES_SUCCESS";
export const MY_COURSES_FAIL = "MY_COURSES_FAIL";

export const ALL_COURSES_REQUEST = "ALL_COURSES_REQUEST";
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAIL = "ALL_COURSES_FAIL";

export const ENROLL_COURSE_REQUEST = "ENROLL_COURSE_REQUEST";
export const ENROLL_COURSE_SUCCESS = "ENROLL_COURSE_SUCCESS";
export const ENROLL_COURSE_FAIL = "ENROLL_COURSE_FAIL";

export const SSO_LOGIN_REQUEST = "SSO_LOGIN_REQUEST";
export const SSO_LOGIN_SUCCESS = "SSO_LOGIN_SUCCESS";
export const SSO_LOGIN_FAIL = "SSO_LOGIN_FAIL";

export const USER_REPORT_REQUEST = "USER_REPORT_REQUEST";
export const USER_REPORT_SUCCESS = "USER_REPORT_SUCCESS";
export const USER_REPORT_FAIL = "USER_REPORT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";